import React from 'react';

import useFetch from 'utils/useFetch';

import ErrorAlert from 'components/AlertMessage/ErrorAlert';
import Loading from 'components/Loading';
import Collapsable from './Collapsable';

const Organigramme = ({ id }) => {
    const { data, isLoading, error } = useFetch(`/api/groups/${id}/roles`);

    if (isLoading)
        return <Loading />;

    if (error)
        return <ErrorAlert>Il y a eu une erreur dans le chargement des données de l'organigramme</ErrorAlert>

    return (<div>
        <h2 className="h2 mb-3">Organigramme</h2>
        <Collapsable members={data} />
    </div>);
}

export default Organigramme;