import React, { useRef } from 'react';
import {DateISO8601Short } from 'utils/dates';

const DatePicker = ({ value, onChange }) => {
    const dateInputRef = useRef(null);
    const handleChange = e => onChange(new Date(e.target.value));

    return (<input
            type="date"
            className="form-control"
            onChange={handleChange}
            ref={dateInputRef}
            value={DateISO8601Short(value)}
    />);
}

export default DatePicker;