import React from "react"
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const Inscriptions = _ => {
    const value = `
# Processus d'affiliations
## Comment devenir membre ?
Si la marche vous tente:
- présentez-vous au secrétariat d'une marche et faites un essai;
ou
- contactez le responsable du club de votre choix.
Vous pouvez également demander des informations auprès de la Secrétaire Fédérale et cela sans aucun engagement de votre part.
## Les avantages réservés aux Membres des Clubs
Les clubs s'organisent comme ils l'entendent. Il y a cependant de nombreux avantages à être affilié à un club:

- Vous ne marchez pas seul. Si vous le voulez, vous ne partez pas seul.
- Vous êtes couverts par une assurance complémentaire à votre mutuelle.
- Vous bénéficiez d'avantages financiers: sur les effets personnels (vêtements, chaussures, équipement), sur les déplacements en car, sur les organisations du club.
- Vous vivez l'amitié bien réelle au sein des clubs.
## Cotisation
Chaque club est libre de fixer le montant de la cotisation annuelle. Elle n'est cependant pas très élevée.

Pour un montant minime par an, vous serez affilié, assuré et vous bénéficierez de tous les avantages offerts par le club.

A vous de faire le pas ...  
maintenant ...

`;

    return (<div className="container">
        <div className="m-5">
            <ReactMarkdown children={value} remarkPlugins={[remarkGfm]} />
        </div>
    </div>);
}

export default Inscriptions