import React from 'react';

const GroupPresentation = ({ group }) => {

    if (!group)
        return null;

    const { nom, matricule, adresseRue, adresseCodePostal, adresseVille, adressePays, siteWeb } = group;

    const parseUrl = value => {
        const words = value.split(';')

        return words.map((word, index) => {
            return <p><strong><i className="fas fa-globe" /></strong> <a
                onClick={e => e.stopPropagation()}
                href={(word.startsWith("http") ? word : "https://" + word.trim())}
                target="_blank"
                rel="noreferrer" > {(word.startsWith("http") ? word : "https://" + word.trim())}</a></p>;      
        })       
    }

    return (<div className="card border-1 border-primary rounded-4 bg-secondary text-primary mb-4 p-4">
        <h1 className="text-dark">{nom} {matricule && <span className="lead">({matricule})</span>}</h1>
        <div className="row">
            {(adresseRue || adresseCodePostal || adresseVille || adressePays) && <div className="col">
                <h2 className="text-primary">Adresse</h2>
                <p className="text-dark">{adresseRue}</p>
                <p className="text-dark">{adresseCodePostal}{adresseVille && ` - ${adresseVille}`}</p>
                <p className="text-dark">{adressePays}</p>
            </div>}
        </div>

        <div className="row">
            {/*{siteWeb &&<p><strong><i className="fas fa-globe" /></strong> <a*/}
            {/*    onClick={e => e.stopPropagation()}*/}
            {/*    href={(siteWeb.startsWith("http") ? siteWeb : "http://" + siteWeb.trim())}*/}
            {/*    target="_blank"*/}
            {/*    rel="noreferrer"*/}
            {/*>*/}
            {/*    {(siteWeb.trim().startsWith("http") ? siteWeb.trim() : "http://" + siteWeb.trim())}*/}
            {/*</a></p>}*/}
            {(siteWeb && siteWeb.trim() ? parseUrl(siteWeb) : "")}
        </div>
    </div>);
}

export default GroupPresentation;