import React from 'react';
import Input from './Input';
import CheckInput from './CheckInput';

/// <summary>
///     This component is a wrapper for the input component.
///     It allows to use the same component for different types of inputs.
/// </summary>
const InputWrapper = (props) => {
    const { type } = props;

    if (type === "checkbox")
        return <CheckInput {...props} />
    else
        return <Input {...props } />
}

export default InputWrapper;