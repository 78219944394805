import React from 'react';
import { Link } from 'react-router-dom';

const NavMenu = _ => {

    return (<nav id="navMenu" className="navbar navbar-expand-lg navbar-dark bg-primary py-3">
        <div className="container px-5">
            <Link className="navbar-brand" to="/">
                <span className="fw-bolder text-light m-2">FFBMP</span>
                <img src="/assets/logo.png" width="30" height="30" className="d-inline-block align-top" alt="" />
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 small fw-bolder">
                    <li className="nav-item text-light"><Link id="navBarHomeLink" className="nav-link" to="/">Accueil</Link></li>
                    <li className="nav-item text-light"><Link id="navBarWalksLink" className="nav-link" to="/marches">Marches</Link></li>
                    <li id="navBarInfoLink" className="nav-item dropdown">
                        <p className="nav-link dropdown-toggle mb-0" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Informations
                        </p>
                        <ul className="dropdown-menu dropdown-menu" aria-labelledby="navbarDarkDropdownMenuLink">
                            <li><Link id="navBarRegisterLink" className="dropdown-item" to="infos/inscriptions">Inscriptions</Link></li>
                            <li><Link id="navBarBulletinLink" className="dropdown-item" to="infos/bulletin" > Bulletin </Link></li>
                            <li><Link id="navBarAgendaLink" className="dropdown-item" to="infos/agenda" > Agenda Papier </Link></li>
                            <li><Link id="navBarGroupsLink" className="dropdown-item" to="/clubs">Nos clubs</Link></li>
                        </ul>
                    </li>
                    <li className="nav-item text-light"><Link id="navBarAboutLink" className="nav-link" to="/about">À propos</Link></li>
                    <li className="nav-item text-light"><Link id="navBarBibliothequeLink" className="nav-link" to="https://ffbmp.sharepoint.com/sites/Intranet" target='_blank'>Bibliothèque</Link></li>
                </ul>
            </div>
        </div>
    </nav>);
}

export default NavMenu;