import React from 'react';
import { useNavigate } from 'react-router-dom';

import useFetch from 'utils/useFetch';
import Loading from 'components/Loading';

import Marche from './Marche';

const ListesMarches = ({ startSearch, endSearch }) => {
    const navigate = useNavigate();

    const { data, isLoading, error } = useFetch(`api/walks?startDate=${startSearch}&endDate=${endSearch}`);

    const HandleClickMarche = id => navigate(`${id}`);

    if (error)
        return <p>Erreur lors du chargement des marches</p>;

    if (isLoading)
        return <Loading/>;

    if (data?.length === 0)
        return <div className="alert alert-info" role="alert">Aucune marche sur la période</div>;

    return (<div>
        {data?.map((elem) => (
        <div key={elem.id} onClick={_ => HandleClickMarche(elem.id)}>
            <Marche marche={elem} />
            </div>))}
        {data.length === 100 && <p className="text-danger">Vous avez atteint la limite de recherche de 100 marches par recherche</p> }
    </div>);
}

export default ListesMarches;