import React from 'react';
import { useParams } from 'react-router-dom';
import ErrorAlert from 'components/AlertMessage/ErrorAlert';
import Loading from 'components/Loading';
import ErrorBoundary from 'utils/ErrorBoundary';
import useFetch from 'utils/useFetch';

const Orga = _ => {
    const { id } = useParams();

    const { data: group, isLoading, error } = useFetch(`/api/groups/${id}`);

    if (isLoading)
        return <Loading />;

    if (error)
        return <ErrorAlert>Il y a eu une erreur dans le chargement des données du groupe</ErrorAlert>

    return (<div className="container p-5">
        <ErrorBoundary fallback={<>oops</>}>
            <h1 className="mb-4">{group.nom} {group.matricule && <span className="lead">({group.matricule})</span>}</h1>
            <Orgranigramme id={id} />
        </ErrorBoundary>
    </div>);
}

const Orgranigramme = ({ id }) => {
    const { data, isLoading, error } = useFetch(`/api/groups/${id}/roles`);

    if (isLoading)
        return <Loading />;

    if (error)
        return <ErrorAlert>Il y a eu une erreur dans le chargement des données de l'organigramme</ErrorAlert>

    return (<div>
        {data.map(e => (<div className="mb-2" key={e.id}>
            <h3 className="text-primary">{e.nom} {e.prenom}</h3>
            <div className="px-4">
                <p className="text-muted">{e.postes}</p>
                {e.mail && <p><i className="fas fa-envelope" /> {e.mail.split('*')[0]}</p>}
                {e.gsm && <p><i className="fas fa-mobile" /> {e.gsm}</p>}
                {e.telephonne && <p><i className="fas fa-phone" /> {e.telephonne}</p>}
                {e.adresseRue && <p><i className="fas fa-house" /> {e.adresseRue}, {e.adresseCodePostal} {e.adresseVille}</p>}
            </div>
        </div>))}
    </div>);
}

export default Orga;
