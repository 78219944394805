import React from 'react';
import { useNavigate } from 'react-router-dom';
import useFetch from 'utils/useFetch';
import { FormatDate as FormatDateDay } from 'utils/dates';
import ErrorAlert from 'components/AlertMessage/ErrorAlert';
import Loading from 'components/Loading';
import './CardHover.css';

const Marches = ({ id }) => {
    const { data: marches, isLoading, error } = useFetch(`/api/groups/${id}/walks`);
    const navigate = useNavigate();
    const HandleClickMarche = id => navigate(`/marches/${id}`);

    if (isLoading)
        return <Loading />;

    if (error)
        return <ErrorAlert>Il y a eu une erreur dans le chargement des données des marches à venir</ErrorAlert>

    return (<div>
        <h2 className="h2 mb-3">Marches à venir</h2>
        <table className="table table-hover table-striped">
            <thead >
                <tr>
                    <th scope="col"><i className="fas fa-calendar" /> Date</th>
                    <th scope="col"><i className="fas fa-tag" /> Titre de la marche</th>
                </tr>
            </thead>
            <tbody>
                {marches.map(marche => (<Marche key={marche.id} marche={marche} onClick={_=>HandleClickMarche(marche.id)} />))}
            </tbody>
        </table>
    </div>);
}

export default Marches;

const Marche = ({ marche, ...rest }) => {

    const { date, titreMarche } = marche;

    return (<tr {...rest}>
        <th scope="row">{FormatDateDay(date)}</th>
        <td>{titreMarche}</td>
    </tr>);
}