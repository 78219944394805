import React from 'react';
import useMyNavigation from 'hooks/useMyNavigation';
import './Jumbotron.css';

const Jumbotron = _ => {
    const { navigate } = useMyNavigation();

    const handleClickButton = e => {
        e.preventDefault();
        navigate("/marches");
    }

    return (<header className="masthead bg-opacity-75">
        <div className="container h-100" >
            <div className="row h-100 align-items-center">
                <div className="col-12 text-center">
                    <section className="py-5  text-light ">
                        <div className="container px-5 my-5">
                            <div className="text-center">
                                <h2 className="display-1 fw-bolder mb-4">Venez marcher avec nous !!!</h2>
                                <p id="CallToActionFindWalk" className="btn btn-primary btn-lg px-5 py-3 fs-8 fw-bolder" onClick={handleClickButton}>Trouver une marche</p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </header>);
}

export default Jumbotron;