import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Loading from 'components/Loading';
import useFetch from 'utils/useFetch';

const Organigrammes = () => {
    const id = "dfc82482-8cb2-11de-a173-001372568c9a"; // CA

    const { data, isLoading } = useFetch(`/api/groups/${id}`);

    if (isLoading)
        return <Loading />

    return (<div className="container p-5">
        <div className="row mb-4">
            <div className="col">
                    <div className="card border-1 border-primary rounded-4 bg-secondary text-primary p-4">
                        <h1 className="mb-3 text-dark">Fédération Francophone Belge de Marches Populaires</h1>
                    <div className="row">
                        {(data?.adresseRue || data?.adresseCodePostal || data?.adresseVille || data?.adressePays) && <div className="col">
                            <h2 className="text-primary">Adresse</h2>
                            <p className="text-dark">{data?.adresseRue}</p>
                            <p className="text-dark">{data?.adresseCodePostal}{data?.adresseVille && ` - ${data?.adresseVille}`}</p>
                            <p className="text-dark">{data?.adressePays}</p>

                        </div>}
                        <Link to={`/orga/${id}`}>Organigramme du conseil d'administration</Link>
                    </div>
                    </div>
                </div>
        </div>
        <div className="row">
            <h2 className="text-primary">Comités et provinces </h2>
            <div className="col mt-2">
                <Groups id={id} />
            </div>
        </div>
    </div>);
}

const Groups = ({ id }) => {
    const navigate = useNavigate();

    const { data, isLoading } = useFetch(`/api/groups/${id}/hierarchy`);

    const HandleClick = id => navigate(`/province/${id}`);

    if (isLoading)
        return <Loading />

    return (<table className="table table-hover table-striped">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col"><i className="fas fa-tag" /> Nom</th>
                <th scope="col"><i className="fas fa-sitemap" /> Organigramme</th>
                <th scope="col"><i className="fas fa-globe" /> Site web</th>
            </tr>
        </thead>
        <tbody>
            {data?.map(e => (<tr key={e.id} onClick={_ => HandleClick(e.id)}>
                <th scope="row">{e.matricule}</th>
                <td>{e.nom}</td>
                <td><Link to={`/orga/${e.id}`} onClick={e => e.stopPropagation()}>organigramme</Link></td>
                <td>{e.siteWeb && <a onClick={e => e.stopPropagation()} href={e.siteWeb} target="_blank" rel="noreferrer" >site web</a>}</td>
            </tr>))}
        </tbody>
    </table>);
}

export default Organigrammes;