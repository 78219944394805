import Accueil from "./pages/Accueil";
import Legal from "./pages/Legal";
import Marches from "./pages/Marches";
import Marche from "./pages/Marche";
import Organigrammes from "./pages/Organigrammes";
import Organigramme from "./pages/Organigramme";
import Province from "./pages/Organigrammes/Provinces";
import Group from "./pages/Organigrammes/Group";
import Inscriptions from "./pages/Infos/Inscriptions";
import PageEditor from "./pages/Dev/PageEditor";
import APropos from "./pages/Infos/APropos";
import Bulletin from "./pages/Infos/Bulletin";
import Agenda from "./pages/Infos/Agenda";

const AppRoutes = [
    {
        index: true,
        element: <Accueil />
    },
    {
        path: '/marches',
        element: <Marches />
    },
    {
        path: '/marches/:id',
        element: <Marche />
    },
    {
        path: '/clubs',
        element: <Organigrammes />
    },
    {
        path: '/clubs/:id',
        element: <Group/>
    },
    {
        path: '/orga/:id',
        element: <Organigramme />
    },
    {
        path: '/province/:id',
        element: <Province/>
    },
    {
        path: '/infos/inscriptions',
        element: <Inscriptions />
    },
    {
        path: '/infos/bulletin',
        element: <Bulletin />
    },
    {
        path: '/infos/agenda',
        element: <Agenda />
    },
    {
        path: 'about',
        element: <APropos />
    },
    {
        path: '/legal',
        element: <Legal />
    },
    {
        path: '/dev/editor',
        element: <PageEditor/>
    },
    {
        path: '*',
        element: <Accueil/>
    },
];

export default AppRoutes;
