import React, { useState } from 'react';

import ErrorBoundary from 'utils/ErrorBoundary';
import ErrorAlert from 'components/AlertMessage/ErrorAlert';
import Titre from 'components/Titre';

//import JumbotronCarrousel from './JumbotronCarrousel';
import DateSelector from './DateSelector';
import ListesMarches from './ListeMarches';
import { AjouterSemaines, DateISO8601Short } from 'utils/dates';

const Marches = () => {
    const [startSearch, setStartSearch] = useState(new Date());
    const [endSearch, setEndSearch] = useState(AjouterSemaines(new Date(),1));

    return (<ErrorBoundary fallback={<ErrorAlert>Erreur dans le calendrier</ErrorAlert>}>
        {/*<JumbotronCarrousel />*/}
        <div className="container mt-4">
            <Titre>Marches</Titre>
            <DateSelector
                endSearch={endSearch}
                setStartSearch={setStartSearch}
                startSearch={startSearch}
                setEndSearch={setEndSearch}
            />
            <div className="row justify-content-center mt-5">
                <div className="col-lg-11 col-xl-9 col-xxl-9">
                    <section>
                        <ErrorBoundary fallback={<ErrorAlert>Impossible de charger le calendrier</ErrorAlert>}>
                            <ListesMarches
                                startSearch={DateISO8601Short(startSearch)}
                                endSearch={DateISO8601Short(endSearch)}
                            />
                        </ErrorBoundary>
                    </section>
                </div>
            </div>
        </div>
    </ErrorBoundary>);
}

export default Marches;
