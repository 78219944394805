import React from 'react'; 
import { Link } from 'react-router-dom';

const Footer = _ => {
    return (<footer id="footer" className="bg-primary text-light py-4 mt-auto">
        <div className="container px-5">
            <div className="row align-items-center justify-content-between flex-column flex-sm-row">
                <div className="col-auto">
                    <div className="small m-0">
                        Copyright &copy; FFBMP 2023
                    </div>
                </div>
                <div className="col-1">                                     
                    <img src="/assets/Ethias/ethias-logo-white.png" className="img-fluid" alt="placeholder" />
                </div>
                <div className="col-1">
                    <img src="/assets/VVF_Logo/horizontal/png/logo-vvf-blanc.png" className="img-fluid" alt="placeholder" />
                </div>
                <div className="col-1">
                    <img src="/assets/Cronolive/CRONOLIVE-WHITE.png" className="img-fluid" alt="placeholder" />
                </div>
                <div className="col-1">
                    <img src="/assets/Logo_VTF_blanc.png" className="img-fluid" alt="placeholder" />
                </div>
                <div className="col-auto">
                    <p className="m-auto">
                        <Link id="FooterLinkToLegal" to="/legal" className="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">
                            Notice légale
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    </footer>);
}

export default Footer; 