import React from 'react';
import { useParams } from 'react-router-dom';
import ErrorBoundary from 'utils/ErrorBoundary';
import useFetch from 'utils/useFetch';
import ErrorAlert from 'components/AlertMessage/ErrorAlert';
import Loading from 'components/Loading';
import GroupPresentation from './GroupPresentation';
import Marches from './Marches';
import Organigramme from './Organigramme';

const Groupe = () => {
    const { id } = useParams();
    const { data: group, isLoading, error } = useFetch(`/api/groups/${id}`);

    if (isLoading)
        return <Loading />;

    if (error)
        return <ErrorAlert>Il y a eu une erreur dans le chargement des données du groupe</ErrorAlert>

    return (<div className="container p-5">
        <ErrorBoundary fallback="Une erreur s'est produite">
            <div className="mb-3">
                <GroupPresentation group={group} />
            </div>
            <div className="row">
                <div className="col-xxl">
                    <Marches id={id} />
                </div>
                <div className="col-xxl">
                    <Organigramme id={id} />
                </div>
            </div>

        </ErrorBoundary>
    </div>);
}

export default Groupe;