import { useNavigate } from 'react-router-dom';

const useMyNavigation = _ => {
    const navigate = useNavigate();

    const goBack = _ => navigate(-1);

    return {
        navigate,
        goBack
    };
};

export default useMyNavigation;