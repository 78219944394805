import React, { useState } from 'react';
import DatePicker from 'components/DatePicker';
import { AjouterMois, AjouterSemaines } from 'utils/dates';

const DateSelector = ({ startSearch, setStartSearch, endSearch, setEndSearch }) => {
    const [select, setSelect] = useState("week");

    const handleSelectToday = e => {
        e.preventDefault();
        setStartSearch(new Date());
        setEndSearch(new Date());
        setSelect("day");
    }

    const handleSelectWeek = e => {
        e.preventDefault();
        setStartSearch(new Date());
        setEndSearch(AjouterSemaines(new Date(), 1));
        setSelect("week");
    }

    const handleSelectMonth = e => {
        e.preventDefault();
        setStartSearch(new Date());
        setEndSearch(AjouterMois(new Date(), 1));
        setSelect("month");
    }

    return (<div className="row">
        <div className="col" />
        <div className="col-auto">
            <div className="W-100">
                <div className="btn-group d-flex">
                    <p
                        className={`btn btn-primary ${select === "day" ? "active" : ""}`}
                        onClick={handleSelectToday}>
                        Aujourd'hui
                    </p>
                    <p
                        className={`btn btn-primary ${select === "week" ? "active" : ""}`}
                        onClick={handleSelectWeek}>
                        Cette semaine
                    </p>
                    <p
                        className={`btn btn-primary ${select === "month" ? "active" : ""}`}
                        onClick={handleSelectMonth}>
                        Ce mois ci
                    </p>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-auto">
                    <div className="row text-center  content-align-center">
                        <div className="col-sm-auto content-align-center">
                            <DatePicker
                                value={startSearch}
                                onChange={val => setStartSearch(val)}
                            />
                        </div>
                        <div className="col-sm-auto my-auto content-align-center">
                            au
                        </div>
                        <div className="col-sm-auto content-align-center">
                            <DatePicker
                                value={endSearch}
                                onChange={val => setEndSearch(val)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col" />
    </div>);
}

export default DateSelector;