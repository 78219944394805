import { useState, useEffect } from "react";

const useFetch = (url) => {
    const [data, setData] = useState();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const controller = new AbortController();

    function reload(){
        setIsLoading(true);

        const options = {
            method: 'GET',
            headers: {
                'signal': controller.signal,
                'Content-Type': "application/json"
            }
        };

        fetch(url, options)
            .then(res => {
                if (res.ok)
                    return res.json();
                else 
                    throw new Error("Fail");                    
            })
            .then(data => setData(data))
            .catch(err => {
                if (err.name === "AbortError") {
                    console.log("request Canceled: " + err );
                } else {
                    setError(err);
                }
            })
            .finally(_ => setIsLoading(false));
    }

    useEffect(_ => {
        reload();

        return () => {
            controller.abort();
            setError();
        };
    }, [url]);

    return { data, error, isLoading, reload };
}

export default useFetch;