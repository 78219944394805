import React, { useState } from 'react';
import Input from 'components/Forms/Input';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm';

const Form = ({ note }) => {
    const InitialState = {
        note: "Commande en ligne de l'agenda de la FFBMP",
        matricule: "",
        nom: "",
        prenom: "",
        adresse: "",
        codePostal: "",
        localite: "",
        email: "",
        phone: ""
    };
    const [state, setState] = useState(InitialState);
    const [errors, setErrors] = useState();
    const [isSend, setIsSend] = useState(false);

    const submit = (e) => {
        setIsSend(false);
        e.preventDefault();
        fetch('/api/forms/order', {
            method: 'POST',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify(state)
        })
            .then(res => {
                if (res.status === 400)
                    throw res;
            })
            .then(_ => setState(InitialState))
            .then(_ => setIsSend(true))
            .catch(err => err.json().then(errmessage => setErrors(errmessage.errors)));
    };

    const HandleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
        console.log(state);
    }

    return (<div className="">
        <div className="container p-4 my-5 ">
            <div className="row bg-primary text-light p-5 rounded-4">
                <div className="col">
                    <h2 className="display-4 mb-4" >Commander l'agenda</h2>
                    <form onSubmit={submit}>
                        <div className="row">
                            <div className="col">
                                <Input
                                    id="FormMatriculeInput"
                                    name="matricule"
                                    label="Matricule du Club"
                                    icon="fa-hashtag"
                                    value={state?.matricule}
                                    error={errors?.Matricule}
                                    onChange={HandleChange}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Input
                                    id="FormNomInput"
                                    name="nom"
                                    label="Nom"
                                    required
                                    icon="fa-user"
                                    value={state?.nom}
                                    error={errors?.Nom}
                                    onChange={HandleChange}
                                />
                            </div>
                            <div className="col">
                                <Input
                                    id="FormPrenomInput"
                                    name="prenom"
                                    label="Prenom"
                                    required
                                    icon="fa-user"
                                    value={state?.prenom}
                                    error={errors?.Prenom}
                                    onChange={HandleChange}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Input
                                    id="FormAdresseInput"
                                    name="adresse"
                                    label="Adresse"
                                    required
                                    icon="fa-road"
                                    value={state?.adresse}
                                    error={errors?.Adresse}
                                    onChange={HandleChange}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <Input
                                    id="FormCodePostalInput"
                                    name="codePostal"
                                    label="Code Postal"
                                    required icon="fa-map"
                                    value={state?.codePostal}
                                    error={errors?.CodePostal}
                                    onChange={HandleChange}
                                />
                            </div>
                            <div className="col-8">
                                <Input
                                    id="FormLocaliteInput"
                                    name="localite"
                                    label="Ville"
                                    required
                                    icon="fa-city"
                                    value={state?.localite}
                                    error={errors?.Localite}
                                    onChange={HandleChange}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <Input
                                    id="FormEmailInput"
                                    name="email"
                                    label="Email"
                                    required
                                    icon="fa-envelope"
                                    value={state?.email}
                                    error={errors?.Email}
                                    onChange={HandleChange}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Input
                                    id="FormPhoneInput"
                                    name="phone"
                                    label="Téléphonne"
                                    required
                                    icon="fa-phone"
                                    value={state?.phone}
                                    error={errors?.Phone}
                                    onChange={HandleChange}
                                />
                            </div>
                        </div>
                        <small>
                            <ReactMarkdown children={note} remarkPlugins={[remarkGfm]} />
                        </small>
                        <button id="FormSubmitButton" className="btn btn-light text-primary mt-4 d-inline" type="submit" value="Submit" >Soumettre <i className="fas fa-paper-plane" /></button>
                        {isSend && <p className="d-inline">
                            <b>Votre demande a bien été soumise <i className="fas fa-thumbs-up fa-beat-fade" /></b>
                        </p>}
                    </form>
                </div>
                <div className="col rounded-2" style={{
                    "backgroundImage": "url('/assets/home.jpg')",
                    "backgroundSize": "cover",
                    "backgroundPosition": "center",
                    "backgroundRepeat": "no-repeat"
                }} />
            </div>

        </div>
    </div>);
}

export default Form;