
const FormatDate = date => {
    if (date) {
        let d = new Date(date)
        if (!isNaN(d)) {
            return d.toLocaleDateString("fr-FR", { weekday: 'short', month: 'short', day: 'numeric', year:'numeric' });
        }
    }
    return date;
}

const DateISO8601Short = date => {
    if (!date)
        return null; 

    let d = new Date(date);
    if (isNaN(d))
        return null;

    return d?.toISOString().split("T")[0];
}

function AjouterMois(date, mois) {
    const dateCopie = new Date(date);
    dateCopie.setMonth(dateCopie.getMonth() + mois);
    return dateCopie;
}

function AjouterSemaines(date, semaines) {
    const dateCopie = new Date(date);
    dateCopie.setDate(dateCopie.getDate() + semaines*7);
    return dateCopie;
}

export { FormatDate, DateISO8601Short, AjouterMois, AjouterSemaines }