import React, { useState } from 'react';
import Form from './FormCommandeAgenda';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ErrorBoundary from 'utils/ErrorBoundary';
import useFetch from 'utils/useFetch';
import Loading from 'components/Loading';

const Agenda = () => {
    const texteExplicatif = `L'agenda FFBMP annonce les marches de la F.F.B.M.P.et de la W.S.V.O.`;

    const { data: costBe, isLoading: loadCostBe, error: errorCostBe } = useFetch(`api/params/PriceCalendarBe`);
    const { data: costEu, isLoading: loadCostEu, error: errorCostEu } = useFetch(`api/params/PriceCalendarEu`);
    const [bank, setBank] = useState("BE14 0682 1600 5283 - BIC:GKCCBEBB");

    const note = `
    Tout envoi fera automatiquement l'objet d'un payement anticipatif. 

    La somme de ${costBe}€ pour un envoi en Belgique 
    
    * ${costEu} € pour un envoi vers un pays de l'union européenne

    à verser au compte promotion de la FFBMP ${bank}.
    L'agenda FFBMP, vous sera envoyé dès réception de votre paiement.`;

    if (errorCostBe || errorCostEu)
        return <p>Erreur lors du chargement des données</p>;

    if (loadCostBe || loadCostEu)
        return <Loading />;

    return (<div>
        <div className="container p-4">
            <div className="row">
                <div className="col-4" style={{
                    "backgroundImage": "url('assets/Agenda.jpg')",
                    "backgroundSize": "contain",
                    "backgroundPosition": "center",
                    "backgroundRepeat": "no-repeat"
                }} />
                <div className="col-8">
                    <div className="card border-1 border-primary rounded-4 bg-secondary text-primary mb-4 p-4">
                        <h1 className="text-dark">L'agenda de la FFBMP</h1>
                    </div>
                    <div className="p-2">
                        <ReactMarkdown children={texteExplicatif} remarkPlugins={[remarkGfm]} />
                    </div>
                    <div className="row px-5 justify-content-center">
                        <div className="col-6">
                            <Card
                                title="Prix"
                                fontAwesomeIcon="fa-euro"
                                tooltip={`Pour un pays de l'union européenne(hormis la Belgique) le prix est de ${costEu} €`}>
                                <p className="lead" >{costBe}€(Belgique) / {costEu}€ (Hors Bel.)</p>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ErrorBoundary>
            <Form note={note} />
        </ErrorBoundary>
    </div>);
}

const Card = ({ title, fontAwesomeIcon, tooltip, children }) => {

    return (<div
        className="card border-1 border-primary rounded-4 bg-secondary text-dark mb-4 p-2 text-center"
        data-toggle="tooltip" data-placement="top" title={tooltip} >
        <h3><i className={`fas ${fontAwesomeIcon} mb-2`}></i> {title}</h3>

        {children}
    </div>);
};

export default Agenda;