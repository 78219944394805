import React from 'react'; 
import Errors from './Errors';
import Required from './Required';

const Input = (props) => {
    const { type, label, error, required, icon, ...rest } = props;
    // type doit être un "text" / "number" / "email" / "password"

    return (<div className="form-group m-2">
        <label>
            <b>
                {label}{required && <Required />}
            </b>
        </label>
        <div className="input-group flex-nowrap">
            {icon && <span className="input-group-text bg-light text-primary" id="addon-wrapping"><i className={`fas ${icon}`}/></span>}
            <input
                className={(`form-control ${error ? "is-invalid" : ""}`)}
                type={type || 'text'}
                required={required}
                {...rest}
                />
        </div>
        <Errors errors={error} />
    </div>);
}

export default Input;
