import React from 'react';
import Footer from './Footer';
import NavMenu from './NavMenu';

const Layout = ({ children }) => {
    return (<div id='Layout' className=" text-dark bg-light">
        <NavMenu />
            {children}
        <Footer />
    </div>);
}

export default Layout;