import React, { useState } from "react"
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const Test =  _ => {
    const [value, setValue] = useState(`# GFM
## Autolink literals

www.example.com, https://example.com, and contact@example.com.

## Footnote

A note[^1]

[^1]: Big note.

## Strikethrough

~one~ or ~~two~~ tildes.

## Table

| a | b  |  c |  d  |
| - | :- | -: | :-: |
| a | b  |  c |  d  |
| a | b  |  c |  d  |
| a | b  |  c |  d  |

## Tasklist

* [ ] to do
* [x] done`);

    return (<>
        <div className="row">
            <div className="col">
                <textarea className="form-control" rows="100" value={value} onChange={e => setValue(e.target.value)} />
            </div>
            <div className="col">
                <ReactMarkdown children={value} remarkPlugins={[remarkGfm]} />
            </div>
        </div>
    </>);
}

export default Test; 