import React, { useState } from 'react';
import FormCommandeBulletin from './FormCommandeBulletin';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ErrorBoundary from 'utils/ErrorBoundary';
import useFetch from 'utils/useFetch';
import Loading from 'components/Loading';

const Bulletin = () => {
    const value = `Dans ce "Bulletin FFBMP" vous lirez de nombreux articles 
    en rapport avec la marche, la nature, des annonces d'organisations spéciales 
    ainsi que des renseignements sur des localités 
    que vous découvrirez lors de vos périples pédestres. 
    Des voyages organisés par les clubs ou la Fédération elle-même vous y sont 
    présentés également: une superbe occasion de découvrir d'autres contrées 
    dans des ambiances "club".`;

    const { data: costBe, isLoading: loadCostBe, error: errorCostBe } = useFetch(`api/params/PriceNewsBe`);
    const { data: costEu, isLoading: loadCostEu, error: errorCostEu } = useFetch(`api/params/PriceNewsEu`);
    const [bank, setBank] = useState("*BE07 0680 9228 0066* (BIC *GKCCBEBB*)");

    const note = `L'abonnement annuel au Bulletin (bimestriel) est de ${costBe} € à verser au compte: ${bank} de la FFBMP, Allée des Cerisiers, 22 - 5300 NAMECHE. L'abonnement court de janvier à décembre avec 6 numéros.

    * Pour un pays de l'union européenne(hormis la Belgique) le prix est de ${costEu} € `;

    if (errorCostBe || errorCostEu)
        return <p>Erreur lors du chargement des données</p>;

    if (loadCostBe || loadCostEu)
        return <Loading />;

    return (<div>
        <div className="container p-4">
            <div className="row">
                <div className="col-4" style={{
                    "backgroundImage": "url('assets/Bulletin.jpg')",
                    "backgroundSize": "contain",
                    "backgroundPosition": "center",
                    "backgroundRepeat": "no-repeat"
                }} />
            
                <div className="col-8">
                    <div className="card border-1 border-primary rounded-4 bg-secondary text-primary mb-4 p-4">
                        <h1 className="text-dark">Le bulletin de la FFBMP</h1>
                    </div>
                    <div className="p-2">
                        <ReactMarkdown children={value} remarkPlugins={[remarkGfm]} />
                    </div>
                    <div className="row px-5 justify-content-center">
                        <div className="col">
                            <Card
                                title="Fréquence"
                                fontAwesomeIcon="fa-newspaper"
                                tooltip="15 de chaque mois pair">
                                <p className="lead" >Bimestriel</p>
                            </Card>
                        </div>
                        <div className="col">
                            <Card
                                title="Prix"
                                fontAwesomeIcon="fa-euro"
                                tooltip={`Pour un pays de l'union européenne(hormis la Belgique) le prix est de ${costEu} €`}>
                                <p className="lead" >{costBe}€(Belgique) / {costEu}€ (Hors Bel.)</p>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ErrorBoundary>
            <FormCommandeBulletin note={ note } />
        </ErrorBoundary>
    </div>);
}

const Card = ({ title, fontAwesomeIcon,tooltip, children }) => {

    return (<div
        className="card border-1 border-primary rounded-4 bg-secondary text-dark mb-4 p-2 text-center"
        data-toggle="tooltip" data-placement="top" title={tooltip} >
        <h3><i className={`fas ${fontAwesomeIcon} mb-2`}></i> {title}</h3>
        
        {children}
    </div>);
};

export default Bulletin;