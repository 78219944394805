import React from "react"
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const APropos = _ => {
	const value = `
# Présentation de la FFBMP
## C'est quoi ?
C'est une fédération sportive non compétitive qui rassemble des amateurs de marche, pour découvrir de belles contrées, pour rencontrer des amis, pour bouger parce que "cela fait un bien fou", ...

La FFBMP est une fédération à part entière. Elle regroupe actuellement 163 clubs répartis dans les 5 provinces francophones du pays et à Bruxelles-capitale: certains sont très actifs, d'autres un peu moins. Au 31 décembre 2018, la FFBMP comptait 18.974 membres inscrits dans les clubs. La FFBMP est agréée par le Ministère de la Communauté Française comme fédération de "Sports de Loisirs".

Les clubs de la FFBMP organisent annuellement environ 450 marches: c'est dire que vous pouvez participer tous les week-ends, les jours fériés et de nombreux jours de la semaine. Toutes ces organisations sont annoncées dans un calendrier annuel en vente.

## La marche, est-ce un sport ?
Venez marcher et vous en serez vite convaincus. La marche demande un effort plus ou moins important selon le rythme que l'on s'impose, l'état de santé ou de fatigue dans lequel on se trouve, le circuit, la région, le pays dans lequel on la pratique, ... Chacun participe à son rythme, librement et sans esprit de compétition. C'est une manière originale pour découvrir le plus beau stade du monde: la nature !

## « Structure fédérale »
La FFBMP est structurée en 5 provinciales correspondant aux 5 provinces francophones du pays avec Bruxelles-Capitale. Chaque Comité Provincial gère les clubs de sa province, sous l'autorité du Conseil d'Administration de la fédération.

La FFBMP constitue avec la fédération germanophone (WSVO) de la Fédération Belge des Sports Populaires (FBSP).

La FBSP, fédération nationale belge donc, est membre de la FISP (Fédération Internationale des Sports Populaires), au même titre que près de 40 autres fédérations nationales d'Europe, mais également des autres continents.

Vous pouvez découvrir toutes les composantes de la FISP en anglais ou allemand ici: www.ivv-web.org
Est-ce la même chose
que d'autres marches ?

Non, de multiples points différencient les marches fédérales des autres marches: tous les 5 km environ, vous passerez par un poste de contrôle avec ravitaillement, au retour en salle un repas à prix démocratique est souvent proposé ...
Et puis la vie en club apporte beaucoup au niveau des liens d'amitié qui se créent au sein de ces groupes structurés.

Venez voir, vous ne serez pas déçus !
## Vous voulez d'autres renseignements ?
## Vous voulez acheter le calendrier de la fédération ?
contactez la Secrétaire Fédérale.`;

return (<div className="container">
	<div className="m-5">
		<ReactMarkdown children={value} remarkPlugins={[remarkGfm]} />
	</div>
</div>);
}

export default APropos;