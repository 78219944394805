import React from 'react';

const Titre = ({children}) => {
    return (<div className="text-center mb-5 ">
        <h1 className="display-5 text-primary fw-bolder mb-0">
            <span className="d-inline">
                {children}
            </span>
        </h1>
    </div>);
} 

export default Titre;