import React from 'react';
import { FormatDate as FormatDateDay } from 'utils/dates';
import ErrorAlert from 'components/AlertMessage/ErrorAlert';
import Loading from 'components/Loading';
import Legende from 'components/Marche/Legende';
import useFetch from 'utils/useFetch';
import './CardHover.css';

const Marche = ({ marche }) => { 
    return (<div className="card border-1 border-primary rounded-4 bg-secondary text-dark mb-4 cardhover">
        <div className="card-body">
            <div className="row align-items-center">
                <div className="col-sm-4 m-0">
                    <div className="p-4 rounded-4 bg-primary text-light text-center">
                        <h5 className="fw-bolder mb-3"><i className="fas fa-calendar"/>  {FormatDateDay(marche.date)}</h5>
                        <div className="small fw-bolder mb-2"><i className="fa-solid fa-location-pin" />  {marche.lieuDepart}</div>
                        <div className="small ">{marche.adresseCodePostal} - {marche.adresseVille}</div>     
                    </div>
                </div>
                <div className="col">
                    <div><strong>{marche.titreMarche}</strong></div>
                    <div>({marche.groupMatricule}) {marche.groupNom}</div>
                    <div className="small text-muted ">{marche.description}</div>
                    <Libelles id={marche.id} />
                    <Distances id={marche.id} />
                </div>
            </div>
        </div>
    </div>);
}

const Libelles = ({ id }) => {
    const { data, isLoading, error } = useFetch(`api/walks/${id}/keys`);

    if (isLoading)
        return <Loading />;

    if (error)
        return <ErrorAlert>Erreur de chargement des libelles</ErrorAlert>;

    return data?.map((e,i) => (<Legende key={i} >
        {e.icon && <i className={(`fas ${e.icon}`)} />} {e?.libelle}
    </Legende>));
}

const Distances = ({ id }) => {
    const { data, isLoading, error } = useFetch(`api/walks/${id}/distances`);

    if (isLoading)
        return <Loading />;

    if (error)
        return <ErrorAlert>Erreur de chargement des distances</ErrorAlert>;

    return data?.map((e,i) => (<p className="mb-0 small" key={i}>
        {e?.distance && `${e.distance} Km | `}{e?.heureDepart && e.heureDepart}
    </p>))

}

export default Marche;