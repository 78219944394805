import React from 'react';

const Collapsable = ({ members }) => {
    
    return (<div className="accordion" id="accordionExample" >
        {members.map(member => <Element key={member.id} member={member} />)}
    </div >);
}

const Element = ({ member }) => {
    const { id, nom, prenom, postes, mail, gsm, telephonne, adresseRue, adresseCodePostal, adresseVille } = member;
    console.log(member);

    const uniqueIdCollapsable = nom.replace(" ", "") + prenom.replace(" ", "") + adresseVille.replace(" ", "");//utilisé pour identifier le responsable dans l'ouverture de l'accordéon

    return (<div className="accordion-item border-0 mb-2" key={id}>
        <h2 className="accordion-header" id="headingOne">
            <button className="accordion-button bg-secondary border border-primary rounded-4" type="button" data-bs-toggle="collapse" data-bs-target={"#" + uniqueIdCollapsable} aria-expanded="true" aria-controls={uniqueIdCollapsable}>
                <div className="justify-content-between">
                    <p className="mb-2"><strong>{nom} {prenom}</strong></p>
                    <p className="mb-0"><span className="text-muted small">{postes}</span></p>
                </div>
            </button>
        </h2>
        <div id={uniqueIdCollapsable} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div className="accordion-body">
                <h5 className="h5">Contact: </h5>
                {mail && <p><i className="fas fa-envelope" /> {mail.split('*')[0]}</p>}
                {gsm && <p><i className="fas fa-mobile" /> {gsm}</p>}
                {telephonne && <p><i className="fas fa-phone" /> {telephonne}</p>}
                {adresseRue && <p><i className="fas fa-house" /> {adresseRue}, {adresseCodePostal} {adresseVille}</p>}
            </div>
        </div>
    </div>);
}

export default Collapsable;

