import { useParams, useNavigate, Link } from 'react-router-dom';
import Loading from 'components/Loading';
import useFetch from 'utils/useFetch';
import ErrorBoundary from '../../../utils/ErrorBoundary';

const Province = () => {
    const { id } = useParams();

    const { data, isLoading } = useFetch(`/api/groups/${id}`);

    if (isLoading)
        return <Loading />

    return (<div className="container p-5">
        <ErrorBoundary fallback="une erreur s'est produite lors du chargement">
            <Banner group={data}/>
            <GroupesEnfants id={id} />
        </ErrorBoundary>
    </div>);
}

const Banner = ({ group }) => {

    const { id, nom, matricule, adresseRue, adresseCodePostal, adresseVille, adressePays } = group;

    return (<div className="row mb-3">
        <div className="col ">
            <div className="card border-1 border-primary rounded-4 bg-secondary text-primary mb-4 p-4">
                <h1 className="text-dark">{nom} {matricule && <span className="lead">({matricule})</span>}</h1>
                <div className="row">

                    {(adresseRue || adresseCodePostal || adresseVille || adressePays) && <div className="col">
                        <h2 className="text-primary">Adresse</h2>
                        <p className="text-dark">{adresseRue}</p>
                        <p className="text-dark">{adresseCodePostal}{adresseVille && ` - ${adresseVille}`}</p>
                        <p className="text-dark">{adressePays}</p>

                    </div>}
                    <Link to={`/orga/${id}`}>Organigramme</Link>
                </div>
            </div>
        </div>
    </div>);
}

const GroupesEnfants = ({ id }) => {
    const navigate = useNavigate();
    const { data, isLoading } = useFetch(`/api/groups/${id}/hierarchy`);

    const HandleClick = id => navigate(`/clubs/${id}`);

    const parseUrl = value => {
        const words = value.split(';')

        return words.map((word, index) => {            
            return <p><a
                onClick={e => e.stopPropagation()}
                href={(word.startsWith("http") ? word : "https://" + word.trim())}
                target="_blank"
                rel="noreferrer" > {(word.startsWith("http") ? word : "https://" + word.trim())}</a></p>;
        })        
    }

    if (isLoading)
        return <Loading />

    if (data.length === 0)
        return null;

    return (<div className="row">
        <h2 className="text-primary">Clubs Associés</h2>
        <div className="col"> 
            <table className="table table-hover table-striped">
                <thead >
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col"><i className="fas fa-tag" /> Nom</th>
                        <th scope="col"><i className="fas fa-users" /> Membres actifs</th>
                        <th scope="col"><i className="fas fa-globe" /> Site web</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map(e => (<tr key={e.id} onClick={_ => HandleClick(e.id)}>
                        <th scope="row">{e.matricule}</th>
                        <td>{e.nom}</td>
                        <td>{e.membresActifs}</td>
                        {/*<td>{e.siteWeb &&*/}
                        {/*    <a*/}
                        {/*        onClick={e => e.stopPropagation()}*/}
                        {/*        href={(e.siteWeb.startsWith("http") ? e.siteWeb : "https://" + e.siteWeb.trim())}*/}
                        {/*        target="_blank"*/}
                        {/*        rel="noreferrer"*/}
                        {/*    >*/}
                        {/*    {(e.siteWeb.trim().startsWith("http") ? e.siteWeb.trim() : "https://" + e.siteWeb.trim())}*/}
                        {/*    </a> }*/}
                        {/*</td>*/}
                        <td>{(e.siteWeb && e.siteWeb.trim() ? parseUrl(e.siteWeb) : "")}</td>
                    </tr>))}
                </tbody>
            </table>
        </div>
    </div>);
}

export default Province;