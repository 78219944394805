import React from 'react';
import Jumbotron from './Jumbotron';
import ErrorBoundary from 'utils/ErrorBoundary';
import ErrorAlert from 'components/AlertMessage/ErrorAlert';

const Accueil = () => {
    return (<ErrorBoundary fallback={<ErrorAlert>Erreur dans le chargement de la page d'accueil</ErrorAlert> }>
        <main className="flex-shrink-0 bg-light">
            <Jumbotron />
            <div className="row m-0 justify-content-center">
                <div className="col-8 p-5">
                    <div className="text-center justify-content-center m-auto">
                        <h2 className="display-4 fw-bolder">
                            <span className="text-gradient text-primary d-inline">
                                À Propos
                            </span>
                        </h2>
                        <p className="lead fw-light mb-4">FFBMP, c'est quoi ?</p>
                        <p className="text-muted">Il s'agit d'une fédération sportive non compétitive qui rassemble des amateurs de marche, pour découvrir de belles contrées, pour rencontrer des amis, pour bouger parce que "cela fait un bien fou"</p>
                    </div>
                </div>
            </div>
        </main>
    </ErrorBoundary>);
}

export default Accueil;
