import React from 'react';
import Errors from './Errors';
import Required from './Required';

//attention, pour fonctionner, il faut que le type soit checkbox
const CheckBox = (props) => {
    const { label, value, error, required, ...rest } = props;

    return (<div className='form-check'>
        <input
            className="form-check-input"
            checked={value}
            required={required}
            {...rest}
        />
        <label>{label}{required && <Required />}</label>
        <Errors errors={error}/>
    </div>);
}

export default CheckBox;