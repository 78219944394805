import React from 'react'; 

const Legende = ({children}) => {
    return <div className="d-inline" style={{ marginRight: '5px' }}>
        <span className="badge bg-primary text-light pill">
            {children}
        </span>
    </div>
}

export default Legende;